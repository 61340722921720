<template>
  <v-container>
    <button class="back-button">
      <router-link :to="{name:'CustomerSupport'}">
        <v-icon size="18">mdi-arrow-left</v-icon>
        Back
      </router-link>
    </button>
    <template v-if="ticket">
      <h2 class="pl-2 mt-8 mb-1">
        {{ ticket.subject }}
      </h2>
      <v-row no-gutters>
        <v-col lg="8" order="2" order-lg="1" sm="12">
          <v-card class="shadow pa-5 mt-8">
            <div v-for="(reply,index) in ticket.replies" :key="reply.id">
              <div v-if="reply.user_id" class="d-flex">
                <v-list-item-avatar
                    class="text-h5 font-weight-light white--text"
                    color="teal"
                    rounded
                >
                  <view-image :image="reply.user.profile_image"></view-image>
                </v-list-item-avatar>
                <div>
                  <p class="mb-0 font-16 font-weight-medium">
                    {{ reply.user.first_name }} {{ reply.user.last_name }}
                  </p>
                  <span class="font-12">
                    {{ moment(reply.created_at).format('ddd DD MMMM HH:mm A') }}
                  </span>
                </div>
              </div>
              <div v-else-if="reply.staff_id" class="d-flex">
                <v-list-item-avatar
                    class="text-h5 font-weight-light white--text"
                    color="teal"
                >
                  <view-image :height="48" :image="'logo/qportal-circular.png'" :width="48"></view-image>
                </v-list-item-avatar>
                <div>
                  <p class="mb-0 font-16 font-weight-medium">
                    Qportal Team
                  </p>
                  <span class="font-12">
                    {{ moment(reply.created_at).format('ddd DD MMMM HH:mm A') }}
                  </span>
                </div>
              </div>
              <p style="padding-left: 60px">
                {{ reply.description }}
              </p>
              <p style="padding-left: 60px">
                <a v-for="attachment in reply.attachments" :key="attachment.id" :href="s3BucketURL+attachment.file_path"
                   class="d-block" target="_blank">
                  <img :src="AttachmentIcon" alt="" height="16" width="16">
                  {{ attachment.original_file_name }}
                </a>
              </p>
              <v-divider v-if="index !== ticket.replies.length-1"/>
              <br>
            </div>
          </v-card>
        </v-col>
        <v-col lg="4" order="1" order-lg="2" sm="12">
          <v-card class="shadow pa-5 mt-8">
            <div class="d-flex justify-space-between mb-2">
              <span class="font-weight-medium">Ticket Number</span>
              <span>TK-{{ ticket.id }}</span>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <span class="font-weight-medium">Date Created</span>
              <span> {{ moment(ticket.created_at).format('ddd DD MMM HH:mm A') }}</span>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <span class="font-weight-medium">Last Activity</span>
              <span>{{ ticket.last_activity }}</span>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <span class="font-weight-medium">Category</span>
              <span>
                {{ ticket.category }}
              </span>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <span class="font-weight-medium">Requester Name</span>
              <span>
                {{ ticket.first_name }} {{ ticket.last_name }}
              </span>
            </div>
            <div v-if="ticket.severity !== 0" class="d-flex justify-space-between mb-2">
              <span class="font-weight-medium">Severity Level</span>
              <span>
                {{ constants.SeveritiesLevel[ticket.severity] }}
              </span>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <span class="font-weight-medium">Status</span>
              <span :class="`support-status ${ticket.status.name.toLowerCase()}`">
                {{ ticket.status.name }}
              </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="ticket.status_id === constants.SUPPORT_STATUS.OPEN">
        <v-col class="pl-5" lg="8">
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submitReply">
            <div class="d-flex justify-start align-start">
              <v-textarea
                  v-model="reply.description"
                  background-color="#fff"
                  class="description-input"
                  name="description"
                  outlined
                  placeholder="Description"
                  rows="4"
              ></v-textarea>
            </div>
            <label for="attachments" role="button">
              <input
                  id="attachments"
                  accept="image/*"
                  multiple
                  style="display: none"
                  type="file"
                  @change="onFilePicked"
              />
              <span class="attachment-box">
                Add files here
              </span>
            </label>

            <br>
            <div v-for="(file,index) in reply.attachments" :key="file.name" class="d-flex justify-start">
                <span style="width: 300px">
                  {{ file.name.slice(0, 50) }}
                </span>
              <v-btn class="bg-transparent" elevation="0" @click="sliceImage(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="d-flex justify-end">
              <v-btn class="submit-btn" elevation="0" type="submit">
                Submit Request
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>

    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import AttachmentIcon from "@/assets/images/attachment-icon.png"
import constants from "@/utils/constants";

export default {
  name: "CustomerSupportDetails",
  computed: {
    constants() {
      return constants
    },
    moment() {
      return moment
    }
  },
  data() {
    return {
      ticket: null,
      AttachmentIcon: AttachmentIcon,
      user: null,
      reply: {
        description: "",
        attachments: []
      },
      valid: true
    };
  },
  methods: {
    onFilePicked(e) {
      this.reply.attachments = [...this.reply.attachments, ...e.target.files]
    },
    sliceImage(index) {
      if (Array.isArray(this.reply.attachments) && index >= 0 && index < this.reply.attachments.length) {
        // Use Array.slice to extract the image at the specified index
        this.reply.attachments.splice(index, 1);
      }
    },
    fetchTicket() {
      this.showLoader("Fetching");
      this.$http
          .get(`venues/support/customer-support/${atob(this.$route.params.id)}`)
          .then(res => {
            this.ticket = res.data.data
          })
          .catch(err => {
            this.errorChecker(err)
          })
          .finally(() => {
            this.hideLoader()
          })
    },
    submitReply() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return false;
      }
      const formData = new FormData();
      formData.append('ticket_id', atob(this.$route.params.id))
      Object.keys(this.reply).forEach(key => {
        if (key === 'attachments') {
          if (this.reply.attachments.length) {
            this.reply.attachments.forEach((file, index) => {
              formData.append(`attachments[${index}]`, file)
            })
          }
        } else {
          formData.append(key, this.reply[key])
        }
      })
      this.showLoader("Submitting")
      this.$http.post('/venues/support/reply', formData)
          .then(res => {
            if (res.status === 200) {
              this.reply.description = ""
              this.reply.attachments = []
              this.fetchTicket()
            }
          })
          .catch(error => {
            this.errorChecker(error)
          })
          .finally(() => {
            this.hideLoader()
          })
    }
  },
  mounted() {
    this.fetchTicket()
    this.user = this.$store.getters['userInfo'];
  }

};
</script>

<style lang="scss" scoped>

.shadow {
  box-shadow: none !important;
}

.v-card {
  margin: 8px !important;
  border-radius: 12px;
  border: 1px solid #DCDCDC;
  background: #FFF;
}

.back-button {
  background: transparent !important;
  font-weight: 500;

  &, * {
    color: #112A46 !important;
  }
}


.attachment-box {
  border-radius: 0.375rem;
  border: 1px dashed #CECECE;
  background: #F5F8FA;
  width: 100%;
  min-height: 5.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

</style>
<style>

.description-input {
  border-radius: 1rem;

  fieldset {
    border: none !important;
  }
}

.submit-btn {
  background: #DCDCDC !important;
  color: #565C68 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.bg-transparent {
  background: transparent !important;
}
</style>